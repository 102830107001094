<script setup lang="ts">
const socialLinks = ref([
  {
    to: 'https://blog.saudetrevo.com.br/',
    name: 'blog',
    icon: 'ic:outline-newspaper',
    alt: 'Botão de link para o Blog da Saúde Trevo',
  },
  {
    to: 'https://www.facebook.com/people/Trevo/100085457634073/',
    name: 'facebook',
    icon: 'ion:social-facebook',
    alt: 'Botão de link para o Facebook da Saúde Trevo',
  },
  {
    to: 'https://www.instagram.com/saudetrevo/',
    name: 'instagram',
    icon: 'ion:logo-instagram',
    alt: 'Botão de link para o Instagram da Saúde Trevo',
  },
  {
    to: 'https://www.linkedin.com/company/trevo-sa%C3%BAde/',
    name: 'linkedin',
    icon: 'bx:bxl-linkedin',
    alt: 'Botão de link para o Linkedin da Saúde Trevo',
  },
])
</script>

<template>
  <footer class="bg-neutral-90 pt-16 pb-6">
    <div
      class="flex flex-col justify-start items-center xl:items-start divide-y mx-auto xl:flex-row xl:justify-around xl:container xl:divide-y-0 xl:divide-x"
    >
      <section class="flex flex-col items-center px-8 pb-8 text-center gap-3 xl:flex-1">
        <p class="text-xl font-bold">
          Nossas redes sociais
        </p>

        <ul class="flex items-center gap-6">
          <li v-for="link in socialLinks" :key="link.name">
            <nuxt-link
              :to="link.to" :alt="link.alt" target="_blank" external
              class="w-7 h-7 rounded-full bg-primary-50 flex items-center justify-center text-white cursor-pointer"
            >
              <Icon :name="link.icon" class="text-white w-4 h-4" />
            </nuxt-link>
          </li>
        </ul>

        <div>
          <span class="text-neutral-20 text-xs">Veja nossas políticas:</span>
          <p>
            <nuxt-link to="/compra-garantida" class="cursor-pointer text-information-50 text-xs">
              Políticas de Cancelamento,
            </nuxt-link>
            <nuxt-link to="/politicas-de-privacidade" class="cursor-pointer text-information-50 text-xs">
              Políticas de Privacidade e
            </nuxt-link>
            <nuxt-link to="/termos-e-condicoes" class="cursor-pointer text-information-50 text-xs">
              Termos e Condições
            </nuxt-link>
          </p>
        </div>
      </section>

      <section class="flex flex-col items-center px-8 xl:flex-1 pt-8 xl:pt-0">
        <p class="text-xl font-bold">
          Compre com segurança
        </p>
        <div class="flex mt-3 gap-3">
          <NuxtLink to="https://www.reclameaqui.com.br/empresa/saude-trevo/" target="_blank" no-prefetch alt="tag do reclame aqui" external>
            <Img
              src="https://web-cdn.saudetrevo.com.br/illustrations/reclame_aqui.svg"
              alt="Selo de qualidade da Saúde Trevo" width="110" height="28"
            />
          </NuxtLink>
        </div>
      </section>
    </div>

    <div class="flex justify-center items-center xl:mt-0 mt-6">
      <span class="text-center text-xs leading-4 text-neutral-30">
        © 2024 Saúde Trevo – Todos os direitos reservados - Razão Social:
        PROJETO TREVO APOIO E GESTAO DA SAUDE LTDA – CNPJ: 45.453.448/0001-07
      </span>
    </div>
  </footer>
</template>
